
  export default {
    name: `LoginPage`,
    head() {
      return {
        title: `Login`
      }
    },
    mounted() {
      // this.$router.push({ path: '/', query: { login: true } })
      if (!this.$auth.loggedIn) {
        this.$store.commit(`drawerMenu/closeDrawer`)
        this.$auth.login()
      } else {
        this.$router.push({ path: `/` })
      }
    }
  }
